import { Directive, DirectiveBinding } from "vue";

export const clickoutside: Directive = {
	beforeMount: (el: any, binding: DirectiveBinding) => {
	  el.clickOutsideEvent = (event: Event): void => {
			if (!(el === event.target || el.contains(event.target))) {
				binding.value();
			}
	  };
	  document.addEventListener("click", el.clickOutsideEvent);
	},
	unmounted: (el: any) => {
	  document.removeEventListener("click", el.clickOutsideEvent);
	},
};