
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbRadioGroup,
	props: {
		options: {
			type: Array
		},
		name: {
			type: String
		},
		modelValue: {
			type: Object
		}
	},
	emits: ["update:modelValue"]
});
