
import { defineComponent } from "vue";
import { EbTrinket } from "./eb-trinket";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbTrinket,
	setup() {
		const component: EbTrinket = new EbTrinket();

		return { component };
	}
});
