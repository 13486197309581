/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { getTranslatedText } from "@/data/providers/localization-provider";
import { reactive } from "vue";
import { ClassroomJoinState } from "./classroom-join-state";
import * as firebase from "firebase/app";
import { authentication } from "@/data/providers/authentication-provider";
import router from "@/router";

export class ClassroomJoinModel {
	// State for Classroom Join View
	public state: ClassroomJoinState = reactive(new ClassroomJoinState());

	/**
	 * Get Translated text for the classroom view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("classroom", key);
	} 

	public addStudentToClass(classID: string): void {
		this.state.joinError = false;
		authentication.db.collection("classrooms").doc(classID).update({
			students: firebase.default.firestore.FieldValue.arrayUnion(authentication.currentUser.value?.uid)
		}).then(() => {
			router.push("/classroom");
		}).catch((err: Error) => {
			console.log(err);
			this.state.joinError = true;
		});
	}
} 