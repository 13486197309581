
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbTab,
	props: {
		title: {
			type: String
		},
		icon: {
			type: String
		},
		active: {
			type: Boolean
		}
	}
});
