
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { EbCard } from "./eb-card";

export default defineComponent({
	name: ComponentName.EbCard,
	props: {
		title: {
			type: String
		},
		subtitle: {
			type: String
		},
		color: {
			type: String
		},
		image: {
			type: String
		}
	},
	setup() {
		const component: EbCard = new EbCard();

		return { component };
	}
});
