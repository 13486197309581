
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { EbUserMenu, isDropdownOpen } from "./eb-user-menu";
import { state } from "@/data/providers/global-provider";
import { authentication } from "@/data/providers/authentication-provider";

export default defineComponent({
	name: ComponentName.EbUserMenu,
	setup() {
		const component: EbUserMenu = new EbUserMenu();

		return { component, state, isDropdownOpen, authentication };
	}
});
