
import { defineComponent, watchEffect } from "vue";
import { ComponentName } from "../constants";
import { selectedTab, Tab } from "./eb-selector";
import { Data } from "../types";

export default defineComponent({
	name: ComponentName.EbToolbar,
	props: {
		items: {
			type: Object as () => Tab
		},
		selected: {
			type: String
		}
	},
	setup(props: Data) {
		watchEffect(() => {
			selectedTab.value = props.selected as string;
		});
		
		return { selectedTab };
	}
});
