
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbPageHeader,
	props: {
		title: {
			type: String
		},
		noBorder: {
			type: Boolean
		},
		beta: {
			type: Boolean
		},
		backArrow: {
			type: Boolean
		}
	}
});
