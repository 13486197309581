
import { defineComponent } from "vue";

import { defaultNavigation } from "@/data/providers/navigation-provider";

export default defineComponent({
	name: "EditorLayout",
	setup() {
		return { defaultNavigation };
	}
});
