
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { EbToolbar } from "./eb-toolbar";
import { state } from "@/data/providers/global-provider";

export default defineComponent({
	name: ComponentName.EbToolbar,
	setup() {
		const component: EbToolbar = new EbToolbar();

		return { component, state };
	}
});
