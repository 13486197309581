import { microbit } from "@/platforms/microbit";
import { python } from "@/platforms/python";
import { html } from "@/platforms/html";
import { circuitpython } from "@/platforms/circuitpython";
import { raspberrypi } from "@/platforms/raspberrypi";
import { EditorButtons } from "@/views/editor/editor-type";

export enum Action {
	RunPython = "RunPython",
	DownloadHex = "DownloadHex"
}

export interface Platform {
	name: string;
	image: string;
	icon?: string;
	key: string;
	description?: string;
	color: string;
	actions?: Array<EditorButtons>;
}

export const platforms: Array<Platform> = [ python, html, microbit, circuitpython, raspberrypi ];