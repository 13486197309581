
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { ClassroomModel } from "./classroom-detail-model";

export default defineComponent({
	name: View.ClassroomDetail,
	setup() {
		const view: ClassroomModel = new ClassroomModel();

		onMounted(() => {
			view.init();
		});

		return { view };
	}
});
