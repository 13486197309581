
import { defineComponent } from "vue";
import { platforms } from "@/data/providers/platform-provider";
import { View } from "@/views/constants";
import { LoginModel } from "./login-model";
import { FirebaseProviders } from "@/data/providers/authentication-provider";

export default defineComponent({
	name: View.Login,
	setup() {
		const view: LoginModel = new LoginModel();

		return { view, platforms, FirebaseProviders };
	}
});
