
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbListItem,
	props: {
		leftTitle: {
			type: String
		},
		leftSubtitle: {
			type: String
		},
		leftIcon: {
			type: String
		},
		leftIconColor: {
			type: String
		},
		rightTitle: {
			type: String
		},
		rightSubtitle: {
			type: String
		},
		rightIcon: {
			type: String
		},
		rightIconColor: {
			type: String
		},
		image: {
			type: String
		},
	}
});
