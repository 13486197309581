import { FirebaseFile } from "@/data/providers/files-provider";

export class ClassroomAssignmentState {
	public assignment: firebase.default.firestore.DocumentData | undefined;

	public classroom: firebase.default.firestore.DocumentData | undefined;

	public submission: firebase.default.firestore.DocumentData | undefined;

	public students: Array<firebase.default.firestore.DocumentData | undefined> = [];

	public submittedAssignments: Array<firebase.default.firestore.DocumentData | undefined> = [];

	public studentComments: string = "";

	public isBusy: boolean = false;

	public selected: string = "assignment-details";

	public starterCode: FirebaseFile | undefined;

	public activeSubmission: number = 0;

	public isSubmissionModalActive: boolean = false;
}