import { ref, Ref } from "vue";

export const selectedTab: Ref<string> = ref("");

export interface Tab {
	title: string;
	key: string;
	count?: number;
	visible: boolean;
	action: VoidFunction;
}