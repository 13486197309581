<template>
	<div
		ref="blocklyDiv"
		:class="size"
		class="w-full h-full overflow-hidden rounded rounded-tl-none blocklyDiv"
	/>
	<xml
		ref="blocklyToolbox"
		style="display:none"
	>
		<slot />
	</xml>
</template>

<script>
import { defineComponent } from "vue";
import { EditorModel } from "@/views/editor/editor-model.ts";
import { blocklyDiv } from "@/views/editor/editor-state.ts";

export default defineComponent({
	name: "BlocklyComponent",
	props: {
		size: String
	},
	setup() {
		return { blocklyDiv };
	},
	data() {
		return {
			workspace: null
		};
	},
	mounted() {
		// eslint-disable-next-line @typescript-eslint/typedef
		const options = this.$props.options || {};
		
		// eslint-disable-next-line @typescript-eslint/typedef
		const editor = new EditorModel();

		if (!options.toolbox) {
			options.toolbox = this.$refs["blocklyToolbox"];
		}
		this.workspace = editor.loadBlockly();
	}
});
</script>

<style lang="postcss">
.blocklyFlyoutLabelText {
  fill: white;
}

.blocklyMainBackground {
  stroke-width: 0 !important;
}

.blocklyToolboxDiv {
	@apply dark:bg-gray-800 dark:text-white;
}

.blocklyTreeRow { 
	@apply dark:border-gray-700;
}

.blocklySvg {
	@apply dark:bg-gray-900;
}
</style>
