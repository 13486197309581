<template>
	<div class="w-full h-full overflow-hidden">
		<iframe
			class="relative w-full rounded-md h-[104%] bottom-8"
			:src="component.getTrinketURL()"
			frameborder="0"
		/>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EbTrinket } from "./eb-trinket";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbTrinket,
	setup() {
		const component: EbTrinket = new EbTrinket();

		return { component };
	}
});
</script>