<template>
	<div class="flex items-center justify-center w-screen h-screen">
		<div id="loading" />
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { View } from "@/views/constants";
import { ClassroomJoinModel } from "./classroom-join-model";
import { formatDate } from "@/data/providers/global-provider";
import router from "@/router/index";

export default defineComponent({
	name: View.ClassroomJoin,
	setup() {
		const view: ClassroomJoinModel = new ClassroomJoinModel();

		view.addStudentToClass(router.currentRoute.value.params.classID.toString());

		return { view, formatDate };
	}
});
</script>
