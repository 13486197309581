import { Project } from "./classroom-detail-types";

export class ClassroomDetailState {
	public classroom: firebase.default.firestore.DocumentData | undefined;

	public isBusy: boolean = false;

	public isCreateAssignmentModalOpen: boolean = false;

	public isAddStudentsModalOpen: boolean = false;

	public selected: string = "active-assignments";

	public students: Array<firebase.default.firestore.DocumentData | undefined> = [];

	public admins: Array<firebase.default.firestore.DocumentData | undefined> = [];

	public activeAssignments: Array<firebase.default.firestore.DocumentData> = [];

	public pastAssignments: Array<firebase.default.firestore.DocumentData> = [];

	public files: Array<Project> = [];

	public joinCode: string = "";
	
	public selectedProject: firebase.default.storage.Reference | undefined;
}