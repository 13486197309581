
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbThumbnailCardSkeleton,
	props: {
		fixed: {
			type: Boolean,
			default: false
		},
		visible: {
			type: Boolean,
			default: true
		},
	},
});
