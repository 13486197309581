
import { defineComponent, watchEffect } from "vue";
import { ComponentName } from "../constants";
import { Data } from "../types";
import { EbToggle } from "./eb-toggle";

export default defineComponent({
	name: ComponentName.EbToggle,
	props: {
		title: {
			type: String,
			default: ""
		},
		subtitle: {
			type: String,
			default: ""
		},
		toggle: {
			type: Boolean,
			default: false
		}
	},
	emits: ["toggled", "untoggled"],
	// eslint-disable-next-line @typescript-eslint/typedef
	setup(props: Data, { emit }) {
		const component: EbToggle = new EbToggle();
		
		function toggleSwitch(): void {
			component.toggled.value = !component.toggled.value;
			if (component.toggled.value) {
				emit("toggled");
			}
			else {
				emit("untoggled");
			}
		}

		watchEffect(() => {
			if (props.toggle) {
				component.toggled.value = true;
			}
		});

		return {
			component, toggleSwitch
		};
	}
});
