
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { ClassroomAssignmentModel } from "./classroom-assignment-model";
import { formatDate } from "@/data/providers/global-provider";

export default defineComponent({
	name: View.ClassroomAssignment,
	setup() {
		const view: ClassroomAssignmentModel = new ClassroomAssignmentModel();

		onMounted(() => {
			view.init();
		});

		return { view, formatDate };
	}
});
