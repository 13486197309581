
import { defineComponent, Ref, ref } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbSelect,
	props: {
		modelValue: {
			type: Object,
		},
		options: {
			type: Array,
		},
		label: {
			type: String,
		}
	},
	emits: ["selected"],
	setup() {
		const selectedValue: Ref = ref();

		return {selectedValue};
	}
	
});
