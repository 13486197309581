<template>
	<div
		class="flex items-center px-4 space-x-2 font-medium transition-all border-t border-l border-r cursor-pointer h-11 rounded-t-md dark:text-white"
		:class="active ? 'bg-white dark:bg-gray-800 dark:border-gray-700' : 'opacity-30 dark:border-gray-400'"
	>
		<component
			:is="icon"
			class="h-5"
		/>
		<h1>{{ title }}</h1>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbTab,
	props: {
		title: {
			type: String
		},
		icon: {
			type: String
		},
		active: {
			type: Boolean
		}
	}
});
</script>
